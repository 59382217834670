import actionTypes from '../../constants/actions/dilucion/dilucion'

export function initialState () {
  return {
    list: [],
    checkedFiltrosDilucion: false,
    filtros: {},
    showModalDilucion: false,
    data: {},
    codigoEstadoMaquinaDilucion: '',
    errorRefractometro: false
  }
}

export function fetchDilucionSuccess (state, {list}) {
  return {
    ...state,
    list
  }
}

export function abrirDetallesSuccess (state, {data}) {
  return {
    ...state,
    showModalDilucion: true,
    data
  }
}

export function cerrarDetallesSuccess (state) {
  return {
    ...state,
    showModalDilucion: false,
    data: {},
    codigoEstadoMaquinaDilucion: ''
  }
}

export function fetchFiltrarDilucionSuccess (state, {filtros}) {
  return {
    ...state,
    filtros
  }
}

export function crearCsvDilucionSuccess (state, {datosExcelDilucion}) {
  return {
    ...state,
    datosExcelDilucion
  }
}

export function imprimirPdfDilucionSuccess (state, {datosPdfDilucion}) {
  return {
    ...state,
    datosPdfDilucion
  }
}

export function actualizarEstadoMaquinaDilucion (state, {codigo}) {
  return {
    ...state,
    codigoEstadoMaquinaDilucion: codigo
  }
}

export function seleccionarTodosDilucion (state) {
  return {
    ...state,
    checkedFiltrosDilucion: true
  }
}

export function deSeleccionarTodosDilucion (state) {
  return {
    ...state,
    checkedFiltrosDilucion: false
  }
}

export function getRefractometroErr (state, {errorRefractometro}) {
  return {
    ...state,
    errorRefractometro: errorRefractometro
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_DILUCION_SUCCESS:
      return fetchDilucionSuccess(state, action)
    case actionTypes.ABRIR_DETALLES_SUCCESS:
      return abrirDetallesSuccess(state, action)
    case actionTypes.CERRAR_DETALLES_SUCCESS:
      return cerrarDetallesSuccess(state, action)
    case actionTypes.FETCH_FILTRAR_DILUCION_SUCCESS:
      return fetchFiltrarDilucionSuccess(state, action)
    case actionTypes.CREATE_CSV_DILUCION_SUCCESS:
      return crearCsvDilucionSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_DILUCION_SUCCESS:
      return imprimirPdfDilucionSuccess(state, action)
    case actionTypes.ACTUALIZAR_ESTADO_MAQUINA_DILUCION:
      return actualizarEstadoMaquinaDilucion(state, action)
    case actionTypes.SELECCIONAR_TODOS_DILUCION:
      return seleccionarTodosDilucion(state, action)
    case actionTypes.DESELECCIONAR_TODOS_DILUCION:
      return deSeleccionarTodosDilucion(state, action)
    case actionTypes.GET_REFRACTOMETRO_ERR:
      return getRefractometroErr(state, action)
    default:
      return state
  }
}