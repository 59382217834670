import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosModalAlbaran from '../../components/albaranManual/FiltrosModalAlbaran'
import {
  abrirModalVacioAlbaranManualDosis
} from '../../actions/albaranManual/albaranManual'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    albaran: state.albaranManual,
    formSimpleTable: state.form.simpleTable   
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalVacioAlbaranManualDosis      
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosModalAlbaran))