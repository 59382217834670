import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {defaultlanguage} from './i18n.js'
import memoize from 'memoize-one';

import {fetchToken, loginSuccess} from './actions/auth/auth'
import loadSessionService from './services/session/loadSession'
import {permisos as permisosConstant} from './constants/permisos'
import {modulos as modulosConstant} from './constants/modulos'

// Public
import PublicLayout from './components/layout/PublicLayout'
import LoginPage from './containers/auth/LoginPage'
import RequestResetPasswordPage from './containers/auth/RequestResetPasswordPage'
import ResetPasswordPage from './containers/auth/ResetPasswordPage'
import LostSessionPage from './containers/auth/LostSessionPage'
import RenovarLicenciaPage from './containers/auth/RenovarLicenciaPage'
import ActualizarAplicacionPage from './containers/auth/ActualizarAplicacionPage'

// Private
import PrivateLayout from './components/layout/PrivateLayout'
import DashboardPage from './containers/dashboard/DashboardPage'
import DashboardProduccionPage from './containers/dashboard/DashboardProduccionPage'

import RazaPage from './containers/raza/RazaPage'
import LineaGeneticaPage from './containers/lineaGenetica/LineaGeneticaPage'
import NivelGeneticoPage from './containers/nivelGenetico/NivelGeneticoPage'
import GrupoVerracoPage from './containers/grupoVerraco/GrupoVerracoPage'
import OperarioPage from './containers/operario/OperarioPage'
import TipoInstalacionPage from './containers/tipoInstalacion/TipoInstalacionPage'
import InstalacionPage from './containers/instalacion/InstalacionPage'
import FichaVerracoPage from './containers/fichaVerraco/FichaVerracoPage'
import NuevoVerracoPage from './containers/fichaVerraco/NuevoVerracoPage'
import EditarVerracoPage from './containers/fichaVerraco/editarVerraco/EditarVerracoPage'
import DuplicarVerracoPage from './containers/fichaVerraco/duplicarVerraco/DuplicarVerracoPage'
import MovimientoVerracoPage from './containers/movimientoVerraco/MovimientoVerracoPage'
import CambioEstadoVerracoPage from './containers/cambioEstadoVerraco/CambioEstadoVerracoPage'
import TipoAlimentacionPage from './containers/planSanitario/tipoAlimentacion/TipoAlimentacionPage'
import VisitaPage from './containers/planSanitario/visita/VisitaPage'
import RegistroAlimentacionPage from './containers/planSanitario/registroAlimentacion/RegistroAlimentacionPage'
import AnalisisExtraccionesPage from './containers/analisisExtracciones/AnalisisExtraccionesPage'
import FormAnalisisExtraccionPage from './containers/analisisExtracciones/FormAnalisisExtraccionPage'
import DilucionPage from './containers/dilucion/DilucionPage'
import PoolExtraccionesPage from './containers/poolExtracciones/PoolExtraccionesPage'
import FormPoolExtraccionPage from './containers/poolExtracciones/FormPoolExtraccionPage'
import AsignacionPoolVerracoPage from './containers/asignacionPoolVerraco/AsignacionPoolVerracoPage'
import EnvasadoPage from './containers/envasado/EnvasadoPage'
import SeguimientoPage from './containers/seguimiento/SeguimientoPage'
import TipoIntervencionSanitariaPage from './containers/planSanitario/tipoIntervencionSanitaria/TipoIntervencionSanitariaPage'
import RegistroIntervencionSanitariaPage from './containers/planSanitario/registroIntervencionSanitaria/RegistroIntervencionSanitariaPage'
import BioseguridadHigienePage from './containers/planSanitario/bioseguridadHigiene/BioseguridadHigienePage'
import RutaPage from './containers/ruta/RutaPage'
import TransportePage from './containers/transporte/TransportePage'
import NuevoTransportistaPage from './containers/transporte/NuevoTransportistaPage'
import EditarTransportistaPage from './containers/transporte/EditarTransportistaPage'
import DuplicarTransportistaPage from './containers/transporte/DuplicarTransportistaPage'
import PanelControlPage from './containers/panelControl/PanelControlPage'
import ProductoPage from './containers/producto/ProductoPage'
import EscandalloPage from './containers/escandallo/EscandalloPage'
import EntradaMercanciaPage from './containers/entradaMercancia/EntradaMercanciaPage'
import ValidacionMateriaPrimaPage from './containers/validacionMateriaPrima/ValidacionMateriaPrimaPage'
import AjusteStockPage from './containers/ajusteStock/AjusteStockPage'
import ClientePage from './containers/cliente/ClientePage'
import PlaningPage from './containers/planing/PlaningPage'
import AlbaranPage from './containers/albaran/AlbaranPage'
import AlbaranManualPage from './containers/albaranManual/AlbaranManualPage.js'
import PedidosVentaPage from './containers/pedidosVenta/PedidosVentaPage'
import FosoPage from './containers/foso/FosoPage'
import ConectividadPage from './containers/conectividad/ConectividadPage'
import AgendaPage from './containers/agenda/AgendaPage'
import InformesExtraccionesPage from './containers/informes/InformesExtraccionesPage'
import InformesStockDosisPage from './containers/informes/InformesStockDosisPage'
import InformesPoolPage from './containers/informes/InformesPoolPage'
import InformesProductividadExtraccionesPage from './containers/informes/InformesProductividadExtraccionesPage'
import InformesSeguimientoExtraccionesPoolPage from './containers/informes/InformesSeguimientoExtraccionesPoolPage'
import InformesProductividadCentroPage from './containers/informes/InformesProductividadCentroPage'
import InformesTrazabilidadPage from './containers/informes/InformesTrazabilidadPage'
import InformesIntervencionesPage from './containers/informes/InformesIntervencionesPage'
import InformesClientesPage from './containers/informes/InformesClientesPage'
import InformesEstadoCentroPage from './containers/informes/InformesEstadoCentroPage'
import EmpresaPage from './containers/empresa/EmpresaPage'
import UsuariosPage from './containers/usuarios/UsuariosPage'
import GrupoUsuariosPage from './containers/grupoUsuarios/GrupoUsuariosPage'
import PlanoPage from './containers/plano/PlanoPage'
import CopiaSeguridadPage from './containers/copiaSeguridad/CopiaSeguridadPage'
import InformesPersonalizadoPage from './containers/informes/InformesPersonalizadoPage'
import ControlMorfologicoPage from './containers/planSanitario/controlCalidad/ControlMorfologicoPage'
import ControlSangrePage from './containers/planSanitario/controlSangre/ControlSangrePage'
import ControlSemenPage from './containers/planSanitario/controlSemen/ControlSemenPage'
import MicrobiologiaPage from './containers/planSanitario/microbiologia/MicrobiologiaPage'
import ComboCausaEliminacionPage from './containers/comboCausaEliminacion/ComboCausaEliminacionPage'
import ComboObservacionesEyaculadoEliminadoPage from './containers/comboObservacionesEyaculadoEliminado/ComboObservacionesEyaculadoEliminadoPage'

import PICTraqPage from './containers/PICTraq/PICTraqPage'
// Admin
import AdminLayout from './components/layout/AdminLayout'
import LicenciasPage from './containers/admin/licencias/LicenciasPage'

import './assets/scss/style.scss'
import AsignacionPedidoPage from './containers/asignacionPedidos/AsignacionPedidoPage';

const securedRoutes = [
  // Verracos
  {id: permisosConstant.FICHA_VERRACOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/FichaVerraco" path="/FichaVerraco" component={FichaVerracoPage} />,
    <Route key="/EditarVerraco/:idVerraco" path="/EditarVerraco/:idVerraco" component={EditarVerracoPage} />
  ]},
  {id: permisosConstant.FICHA_VERRACOS, minLevel: 2, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/NuevoVerraco" path="/NuevoVerraco" component={NuevoVerracoPage} />,
    <Route key="/DuplicarVerraco/:idVerraco" path="/DuplicarVerraco/:idVerraco" component={DuplicarVerracoPage} />
  ]},
  {id: permisosConstant.MOVIMIENTO_VERRACOS, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/MovimientoVerraco" path="/MovimientoVerraco" component={MovimientoVerracoPage} />
  ]},
  {id: permisosConstant.CAMBIOS_ESTADO_VERRACOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/CambioEstadoVerraco" path="/CambioEstadoVerraco" component={CambioEstadoVerracoPage} />
  ]},

  // Plan sanitario
  {id: permisosConstant.BIOSEGURIDAD_HIGIENE, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/BioseguridadHigiene" path="/BioseguridadHigiene" component={BioseguridadHigienePage} />
  ]},
  {id: permisosConstant.VISITAS, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/Visitas" path="/Visitas" component={VisitaPage} />
  ]},
  {id: permisosConstant.TIPOS_ALIMENTACION, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/TipoAlimentacion" path="/TipoAlimentacion" component={TipoAlimentacionPage} />
  ]},
  {id: permisosConstant.REGISTRO_ALIMENTACIONES, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/RegistroAlimentacion" path="/RegistroAlimentacion" component={RegistroAlimentacionPage} />
  ]},
  {id: permisosConstant.TIPOS_INTERVENCION_SANITARIA, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/TipoIntervencionSanitaria" path="/TipoIntervencionSanitaria" component={TipoIntervencionSanitariaPage} />
  ]},
  {id: permisosConstant.REGISTRO_INTERVENCION_SANITARIA, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/RegistroIntervencionSanitaria" path="/RegistroIntervencionSanitaria" component={RegistroIntervencionSanitariaPage} />
  ]},
  {id: permisosConstant.CONTROL_MORFOLOGICO, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/ControlMorfologico" path="/ControlMorfologico" component={ControlMorfologicoPage} />
  ]},
  {id: permisosConstant.CONTROL_SANGRE, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/ControlSangre" path="/ControlSangre" component={ControlSangrePage} />
  ]},
  {id: permisosConstant.CONTROL_SEMEN, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/ControlSemen" path="/ControlSemen" component={ControlSemenPage} />
  ]},
  {id: permisosConstant.MICROBIOLOGIA, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/Microbiologia" path="/Microbiologia" component={MicrobiologiaPage} />
  ]},
  // Extracciones
  {id: permisosConstant.ANALISIS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/AnalisisExtracciones" path="/AnalisisExtracciones" component={AnalisisExtraccionesPage} />,
    <Route key="/EditarAnalisisExtraccion/:idAnalisisExtraccion" path="/EditarAnalisisExtraccion/:idAnalisisExtraccion" component={FormAnalisisExtraccionPage} />
  ]},
  {id: permisosConstant.ANALISIS, minLevel: 2, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/NuevoAnalisisExtraccion" path="/NuevoAnalisisExtraccion" component={FormAnalisisExtraccionPage} />
  ]},
  {id: permisosConstant.DILUCION, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Dilucion" path="/Dilucion" component={DilucionPage} />
  ]},
  {id: permisosConstant.POOL, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/PoolExtracciones" path="/PoolExtracciones" component={PoolExtraccionesPage} />,
    <Route key="/EditarPoolExtraccion/:idPoolExtraccion" path="/EditarPoolExtraccion/:idPoolExtraccion" component={FormPoolExtraccionPage} />,
  ]},
  {id: permisosConstant.ASIGNACION_POOL, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/AsignacionPoolVerraco" path="/AsignacionPoolVerraco" component={AsignacionPoolVerracoPage} />,
  ]},
  
  {id: permisosConstant.POOL, minLevel: 2, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/NuevoPoolExtraccion" path="/NuevoPoolExtraccion" component={FormPoolExtraccionPage} />
  ]},
  {id: permisosConstant.ENVASADO, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Envasado" path="/Envasado" component={EnvasadoPage} />
  ]},
  {id: permisosConstant.SEGUIMIENTO, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Seguimiento" path="/Seguimiento" component={SeguimientoPage} />
  ]},
  {id: permisosConstant.FOSO, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Foso" path="/Foso" component={FosoPage} />
  ]},

  // Planning
  {id: permisosConstant.PLANNING, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
      <Route key="/Planing"  path="/Planing" component={PlaningPage} />
  ]},

  // Pedidos
  {id: permisosConstant.CLIENTES, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/Cliente" path="/Cliente" component={ClientePage} />
  ]},
  {id: permisosConstant.PEDIDO, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/PedidosVenta" path="/PedidosVenta" component={PedidosVentaPage} />
  ]},
  {id: permisosConstant.ALBARANES, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/AsignacionPedido" path="/AsignacionPedidos" component={AsignacionPedidoPage} />,
  ]},
  {id: permisosConstant.ALBARANES, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/AlbaranVenta" path="/AlbaranVenta" component={AlbaranPage} />
  ]},
  {id: permisosConstant.ALBARANES, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/RegistroAlbaranes" path="/RegistroAlbaranes" component={AlbaranManualPage} />
  ]},

  // Informes
  {id: permisosConstant.INFORMES, minLevel: 1, modulos: [modulosConstant.INFORMES], routes: [
    <Route key="/Informes/EstadoCentro" path="/Informes/EstadoCentro" component={InformesEstadoCentroPage} />
  ]},
  {id: permisosConstant.ANALISIS, minLevel: 1, modulos: [modulosConstant.INFORMES], routes: [
    <Route key="/Informes/Extracciones" path="/Informes/Extracciones" component={InformesExtraccionesPage} />,
    <Route key="/Informes/ProductividadExtracciones" path="/Informes/ProductividadExtracciones" component={InformesProductividadExtraccionesPage} />,
    <Route key="/Informes/ProductividadCentro" path="/Informes/ProductividadCentro" component={InformesProductividadCentroPage} />,
    <Route key="/Informes/SeguimientoExtraccionesPool" path="/Informes/SeguimientoExtraccionesPool" component={InformesSeguimientoExtraccionesPoolPage} />
  ]},
  {id: permisosConstant.POOL, minLevel: 1, modulos: [modulosConstant.INFORMES], routes: [
    <Route key="/Informes/Pool"  path="/Informes/Pool" component={InformesPoolPage} />,
    <Route key="/Informes/SeguimientoExtraccionesPool" path="/Informes/SeguimientoExtraccionesPool" component={InformesSeguimientoExtraccionesPoolPage} />
  ]},
  {id: permisosConstant.CLIENTES, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/Informes/Clientes" path="/Informes/Clientes" component={InformesClientesPage} />
  ]},
  {id: permisosConstant.ANALISIS, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/Informes/StockDosis" path="/Informes/StockDosis" component={InformesStockDosisPage} />
  ]},
  {id: permisosConstant.ALBARANES, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/Informes/StockDosis" path="/Informes/StockDosis" component={InformesStockDosisPage} />
  ]},
  {id: permisosConstant.REGISTRO_INTERVENCION_SANITARIA, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/Informes/Intervenciones" path="/Informes/Intervenciones" component={InformesIntervencionesPage} />
  ]},
  {id: permisosConstant.ANALISIS, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/Informes/Trazabilidad" path="/Informes/Trazabilidad" component={InformesTrazabilidadPage} />
  ]},
  {id: permisosConstant.PEDIDO, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/Informes/Trazabilidad" path="/Informes/Trazabilidad" component={InformesTrazabilidadPage} />
  ]},
  {id: permisosConstant.ALBARANES, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/Informes/Trazabilidad" path="/Informes/Trazabilidad" component={InformesTrazabilidadPage} />
  ]},

  {id: 'PERSONALIZADO', minLevel: 1, modulos: [modulosConstant.INFORMES], routes: [
    <Route key="/Informes/Personalizado" path="/Informes/Personalizado" component={InformesPersonalizadoPage} />
  ]},

  // Almacen
  {id: permisosConstant.PRODUCTOS, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/Producto" path="/Producto" component={ProductoPage} />
  ]},
  {id: permisosConstant.ESCANDALLOS, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/Escandallo" path="/Escandallo" component={EscandalloPage} />
  ]},
  {id: permisosConstant.ENTRADAS_MERCANCIAS, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/EntradaMercancia" path="/EntradaMercancia" component={EntradaMercanciaPage} />
  ]},
  {id: permisosConstant.VALIDACION_MATERIAS_PRIMAS, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/ValidacionMateriaPrima" path="/ValidacionMateriaPrima" component={ValidacionMateriaPrimaPage} />
  ]},
  {id: permisosConstant.AJUSTE_STOCK, minLevel: 1, modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], routes: [
    <Route key="/AjusteStock" path="/AjusteStock" component={AjusteStockPage} />
  ]},

  // Mantenimiento de datos.
  {id: permisosConstant.RAZAS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Raza" path="/Raza" component={RazaPage} />
  ]},
  {id: permisosConstant.LINEAS_GENETICAS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/LineaGenetica" path="/LineaGenetica" component={LineaGeneticaPage} />
  ]},
  {id: permisosConstant.NIVEL_GENETICO, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/NivelGenetico" path="/NivelGenetico" component={NivelGeneticoPage} />
  ]},
  {id: permisosConstant.GRUPOS_VERRACOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/GrupoVerraco" path="/GrupoVerraco" component={GrupoVerracoPage} />
  ]},
  {id: permisosConstant.OPERARIOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Operario" path="/Operario" component={OperarioPage} />
  ]},
  {id: permisosConstant.RUTAS, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/Ruta" path="/Ruta" component={RutaPage} />
  ]},
  {id: permisosConstant.TRANSPORTISTAS, minLevel: 1, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/Transporte" path="/Transporte" component={TransportePage} />,
    <Route key="/EditarTransportista/:idTransportista" path="/EditarTransportista/:idTransportista" component={EditarTransportistaPage} />
  ]},
  {id: permisosConstant.TRANSPORTISTAS, minLevel: 2, modulos: [modulosConstant.PEDIDOS_CLIENTES], routes: [
    <Route key="/NuevoTransportista" path="/NuevoTransportista" component={NuevoTransportistaPage} />,
    <Route key="/DuplicarTransportista/:idTransportista" path="/DuplicarTransportista/:idTransportista" component={DuplicarTransportistaPage} />
  ]},
  {id: permisosConstant.TIPOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/TipoInstalacion" path="/TipoInstalacion" component={TipoInstalacionPage} />
  ]},
  {id: permisosConstant.INSTALACIONES, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Instalacion" path="/Instalacion" component={InstalacionPage} />,
  ]},
  {id: permisosConstant.VER_MAPA, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Plano" path="/Plano" component={PlanoPage} />
  ]},
  {id: permisosConstant.VERRACOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/ComboCausaEliminacion" path="/ComboCausaEliminacion" component={ComboCausaEliminacionPage} />
  ]},
  {id: permisosConstant.VERRACOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/comboObservacionesEyaculadoEliminado" path="/comboObservacionesEyaculadoEliminado" component={ComboObservacionesEyaculadoEliminadoPage} />
  ]},

  // Herramientas.
  {id: permisosConstant.PANEL_CONTROL, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/PanelControl" path="/PanelControl" component={PanelControlPage} />
  ]},
  {id: permisosConstant.CONECTIVIDAD, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Conectividad" path="/Conectividad" component={ConectividadPage} />
  ]},
  {id: permisosConstant.PICTRAQ, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/PICTraq" path="/PICTraq" component={PICTraqPage} />
  ]},
  {id: permisosConstant.COPIA_SEGURIDAD, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/CopiaSeguridad" path="/CopiaSeguridad" component={CopiaSeguridadPage} />
  ]},
  {id: permisosConstant.AGENDA, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Agenda" path="/Agenda" component={AgendaPage} />
  ]},

  // Sistema.
  {id: permisosConstant.EMPRESA_CENTROS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Empresa" path="/Empresa" component={EmpresaPage} />
  ]},
  {id: permisosConstant.USUARIOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/Usuarios" path="/Usuarios" component={UsuariosPage} />
  ]},
  {id: permisosConstant.GRUPOS_USUARIOS, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/GrupoUsuarios" path="/GrupoUsuarios" component={GrupoUsuariosPage} />
  ]},
  {id: permisosConstant.DASHBOARD, minLevel: 1, modulos: [modulosConstant.BASICO], routes: [
    <Route key="/DashboardProduccion" path="/DashboardProduccion" component={DashboardProduccionPage} />
  ]}
]

const memoizedSecuredRoutes = memoize((permisos, modulos) => {
  return securedRoutes.filter((securedRoute) => {
    return (!securedRoute.modulos || securedRoute.modulos.some((modulo) => {
      return modulos.indexOf(modulo) !== -1
    })) && (permisos === '-1' || permisos[securedRoute.id] > 0 || securedRoute.id === 'PERSONALIZADO' && permisos[permisosConstant.ANALISIS] > 0 && permisos[permisosConstant.POOL] > 0 && permisos[permisosConstant.VERRACOS] > 0 && permisos[permisosConstant.PLANNING] > 0 && permisos[permisosConstant.CLIENTES] > 0 && permisos[permisosConstant.PEDIDO] > 0 && permisos[permisosConstant.ALBARANES] > 0)
  }).reduce((accumulator, currentValue) => accumulator.concat(currentValue.routes), [])
})

class Routes extends Component {
  constructor (props) {
    super()
    this.state = {
      checkedAuth: false
    }

    if (props.auth.idioma !== defaultlanguage) {
      props.i18n.changeLanguage(props.auth.idioma);
    }
  }

  ensureAuth(props) {
    let auth = props.auth
    if (!props.auth.nombreUsuario) {
      auth = loadSessionService()
      if (auth && auth.nombreUsuario) {
        props.actions.loginSuccess(auth)
      } else if (!auth || !!auth.token) {
        props.actions.fetchToken()

        // TODO Evitar multiples llamadas a fetchToken sin que se pierda el path.
        //new Promise((resolve, reject) => {
        //  this.setState({checkedAuth: true})
        //  props.actions.fetchToken(resolve, reject)
        //}).finally(() => {
        //  this.setState({checkedAuth: false})
        //})
        console.warn('Redirecting to sign in pages, ensure auth fails')
      }
    }
    if (!this.state.checkedAuth) this.setState({checkedAuth: true})
    return !!auth
  }

  componentDidMount() {
    if (!this.props.auth.nombreUsuario) {
      this.ensureAuth(this.props)
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.auth.nombreUsuario && !this.props.auth.token) {
      this.ensureAuth(this.props)
    }
    if (this.props.auth.idioma !== prevProps.auth.idioma) {
      this.props.i18n.changeLanguage(this.props.auth.idioma);
    }
  }

  render () {
    const {
      auth: { permisos, modulos, adminLogin, nombreUsuario, idioma, isCloud }
    } = this.props

    // La comprobacion del lenguage evita que se renderizen elementos en distintos idiomas.
    if (!this.state.checkedAuth || (idioma !== this.props.i18n.language) ) return null
    return (
      <div className={'App ' + this.props.auth.idioma}>
        {adminLogin ? (
          <AdminLayout>
            <Switch>
              <Route exact path="/" component={LicenciasPage}/>
              <Route path="/LostSession" component={LostSessionPage} />
              <Redirect from="*" to="/" />
            </Switch>
          </AdminLayout>
        ) : nombreUsuario ? (
          <PrivateLayout>
            <Switch>
              <Route exact path="/" component={DashboardPage}/>
              <Route path="/LostSession" component={LostSessionPage} />
              {memoizedSecuredRoutes(permisos, modulos)}
              <Redirect from="*" to="/" />
            </Switch>
          </PrivateLayout>
        ) : (
          <PublicLayout showSidebar={false}>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route path="/Login" component={LoginPage} />
              <Route path="/RequestResetPassword" component={RequestResetPasswordPage} />
              <Route path="/ResetPassword" component={ResetPasswordPage} />
              <Route path="/Admin" render={(props) => <LoginPage {...props} adminLogin={true} />} />
              <Route path={isCloud === false ? '/RenovarLicencia' : '/Login'} component={RenovarLicenciaPage} />
              <Route path={isCloud === false ? '/ActualizarAplicacion' : '/Login'} component={ActualizarAplicacionPage} />
              <Redirect from="*" to="/" />
            </Switch>
          </PublicLayout>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    showSimpleModal: state.common.showSimpleModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchToken,
      loginSuccess
    }, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Routes)))