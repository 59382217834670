import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import {abrirDetallesAlbaranManual, editarAlbaranManual, cerrarDetallesAlbaranManual, deleteModalAlbaranManualDosis, abrirDetallesAlbaranManualDosis, imprimirAlbaranManual} from '../../actions/albaranManual/albaranManual'
import ModalAlbaran from '../../components/albaranManual/ModalAlbaran'
import {abrirDetallesPedido} from '../../actions/pedidosVenta/pedidosVenta'
import {fetchPanelControl} from '../../actions/panelControl/panelControl'


export function mapStateToProps (state) {  
  return {
    ...state.albaranManual,
    auth: state.auth,
    panelControl: state.panelControl,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,   
    formModalAlbaran: getFormValues('ModalAlbaran')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirDetallesAlbaranManual,
      editarAlbaranManual,
      cerrarDetallesAlbaranManual,
      deleteModalAlbaranManualDosis,
      abrirDetallesAlbaranManualDosis,
      imprimirAlbaranManual
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalAlbaran)))