import actionTypes from '../../constants/actions/dilucion/dilucion'

export function fetchDilucion () {
  return {
    type: actionTypes.FETCH_DILUCION
  }
}

export function fetchDilucionSuccess (list) {
  return {
    type: actionTypes.FETCH_DILUCION_SUCCESS,
    list
  }
}

export function abrirDetalles (idDilucion) {
  return {
    type: actionTypes.ABRIR_DETALLES,
    idDilucion
  }
}

export function abrirDetallesSuccess (data) {
  return {
    type: actionTypes.ABRIR_DETALLES_SUCCESS,
    data
  }
}

export function cerrarDetalles () {
  return {
    type: actionTypes.CERRAR_DETALLES
  }
}

export function cerrarDetallesSuccess () {
  return {
    type: actionTypes.CERRAR_DETALLES_SUCCESS
  }
}

export function editarDilucion (values) {
  return {
    type: actionTypes.EDITAR_DILUCION,
    values
  }
}

export function fetchFiltrarDilucion (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_DILUCION,
    values
  }
}

export function fetchFiltrarDilucionSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_DILUCION_SUCCESS,
    filtros
  }
}

export function crearCsvDilucion(list){
  return {
    type: actionTypes.CREATE_CSV_DILUCION,
    list
  }
}

export function crearCsvDilucionSuccess(datosExcelDilucion){
  return {
    type: actionTypes.CREATE_CSV_DILUCION_SUCCESS,
    datosExcelDilucion
  }
}

export function imprimirPdfDilucion(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_DILUCION,
    list
  }
}

export function imprimirPdfDilucionSuccess(datosPdfDilucion){
  return {
    type: actionTypes.IMPRIMIR_PDF_DILUCION_SUCCESS,
    datosPdfDilucion
  }
}

export function prepararMaquinaDilucion (values) {
  return {
    type: actionTypes.PREPARAR_MAQUINA_DILUCION,
    values
  }
}

export function cancelarMaquinaDilucion () {
  return {
    type: actionTypes.CANCELAR_MAQUINA_DILUCION
  }
}

export function actualizarEstadoMaquinaDilucion(codigo){
  return {
    type: actionTypes.ACTUALIZAR_ESTADO_MAQUINA_DILUCION,
    codigo
  }
}

export function seleccionarTodosDilucion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_DILUCION
  }
}

export function deSeleccionarTodosDilucion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_DILUCION
  }
}

export function deleteSeleccionDilucion(list){
  return {
    type: actionTypes.DELETE_SELECCION_DILUCION,
    list
  }
}

export function onDeleteDilucion(idDilucion){
  return {
    type: actionTypes.ON_DELETE_DILUCION,
    idDilucion
  }
}

export function getRefractometro(){
  return {
    type: actionTypes.GET_REFRACTOMETRO
  }
}

export function getRefractometroErr(errorRefractometro){
  return {
    type: actionTypes.GET_REFRACTOMETRO_ERR,
    errorRefractometro
  }
}

export default {
  fetchDilucion,
  fetchDilucionSuccess,
  abrirDetalles,
  abrirDetallesSuccess,
  cerrarDetalles,
  cerrarDetallesSuccess,
  editarDilucion,
  fetchFiltrarDilucion,
  fetchFiltrarDilucionSuccess,
  crearCsvDilucion,
  crearCsvDilucionSuccess,
  imprimirPdfDilucion,
  imprimirPdfDilucionSuccess,
  prepararMaquinaDilucion,
  cancelarMaquinaDilucion,
  actualizarEstadoMaquinaDilucion,
  seleccionarTodosDilucion,
  deSeleccionarTodosDilucion,
  deleteSeleccionDilucion,
  onDeleteDilucion,
  getRefractometro,
  getRefractometroErr
}
