import { select, put, call, all, takeLatest, delay, take } from 'redux-saga/effects'
import { initialize, getFormValues } from 'redux-form';
import i18n from '../../i18n';
import { date_parser, dateTimeZones } from '../../util/formatFunctions'
import { downloadDocument } from '../../util/util'

// Constants
import { verracoSiNo as verracoSiNoConstants, translateVerracoSiNo as translateVerracoSiNoConstants } from '../../constants/seguimiento'
import { estadoAlbaran as estadoAlbaranConstants, translateEstadoAlbaran as translateEstadoAlbaranConstants, estadoAlbaranValidacion as estadoValidacion} from '../../constants/albaran'
import actionTypes from '../../constants/actions/albaranManual/albaranManual'

// Actions
import { openSimpleModal, openMensajeAlerta } from '../../actions/common'
import {
  fetchAlbaranesManualSuccess, abrirModalVacioAlbaranManualSuccess, abrirDetallesAlbaranManualSuccess,
  cerrarDetallesAlbaranManualSuccess, abrirModalVacioAlbaranManualDosisSuccess,
  abrirDetallesAlbaranManualDosisSuccess, cerrarDetallesAlbaranManualDosisSuccess, editarAlbaranManualDosisSuccess  
} from '../../actions/albaranManual/albaranManual'

// Services
import getAlbaranesListService from '../../services/albaran/getAlbaranesList'
import getFiltrarAlbaranesListService from '../../services/albaranManual/getFiltrarAlbaranesList'
import deleteAlbaranService from '../../services/albaranManual/deleteAlbaran'
import deleteSeleccionAlbaranesService from '../../services/albaran/deleteSeleccionAlbaranes'
import getCrearCsvAlbaranService from '../../services/albaran/getCrearCsvAlbaran'
import getImprimirPdfAlbaranService from '../../services/albaran/getImprimirPdfAlbaran'
import getAlbaranService from '../../services/albaranManual/getAlbaran'
import fetchNumeroAlbaranService from '../../services/albaran/fetchNumeroAlbaran'
import submitEditarAlbaranService from '../../services/albaranManual/submitEditarAlbaran'
import submitNuevoAlbaranService from '../../services/albaranManual/submitNuevoAlbaran'
import getImprimirDatosAlbaranService from '../../services/albaranManual/getImprimirDatosAlbaran'
import getImprimirDatosAlbaranClienteService from '../../services/albaran/getImprimirDatosAlbaranCliente'
import getImprimirMultiplesAlbaranesService from '../../services/albaran/getImprimirMultiplesAlbaranes'
import getImprimirEtiquetaPDF from '../../services/albaran/getImprimirEtiquetaPDF'
import printronixService from '../../services/comun/printronix'
import getLineaAlbaranRfid from '../../services/albaran/getLineaAlbaranRfid'

// Sagas
import { yesNoModal as yesNoModalSaga } from '../modal/yesNoModal'
import {
  comboCliente as comboClienteSaga, comboDireccionCliente as comboDireccionClienteSaga, comboDireccionClienteSecundaria as comboDireccionClienteSecundariaSaga,
  comboTransportistasCliente as comboTransportistasClienteSaga, comboRutaTransportistaCliente as comboRutaTransportistaClienteSaga,
  comboConductoresTransportistaDeterminado as comboConductoresTransportistaDeterminadoSaga,
  comboVehiculosTransportistaDeterminado as comboVehiculosTransportistaDeterminadoSaga,
  comboTipoDosis as comboTipoDosisSaga, comboRaza as comboRazaSaga, comboLineaGenetica as comboLineaGeneticaSaga,
  comboRazaGranja as comboRazaGranjaSaga,
  comboNivelGeneticoActivo as comboNivelGeneticoActivoSaga,
  comboVerracosExtraccionAlbaran as comboVerracosExtraccionAlbaranSaga, comboPoolsExtraccionAlbaran as comboPoolsExtraccionAlbaranSaga
} from '../combos/combos'
import { obtenerInformeStockDosis as obtenerInformeStockDosisSaga } from '../informes/informes'
import { fetchLineasAlbaranes as fetchLineasAlbaranesSaga } from '../asignacionPedidos/asignacionPedidos'

export function* fetchAlbaranesManual({ filtros }) {
  try {
    let auth = yield select(state => state.auth)
    let albaranesList = []
    if (filtros && Object.keys(filtros).filter(key => key !== 'seleccionar-todo').length > 0) {      
      albaranesList = yield call(getFiltrarAlbaranesListService, filtros, auth.token)
    } else {      
      albaranesList = yield call(getAlbaranesListService)
    }
    yield put(fetchAlbaranesManualSuccess(albaranesList, filtros))    
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchFetchAlbaranesManual() {
  yield takeLatest(actionTypes.FETCH_ALBARANES_MANUAL, fetchAlbaranesManual)
}

export function* onDeleteAlbaranManual({ idAlbaranManual }) {
  try {
    console.log('idAlbaranManual',idAlbaranManual)
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, { modalType: 'onDelete' })
    if (confirmed) {
      const { eliminado } = yield call(deleteAlbaranService, idAlbaranManual)
      let filtros = yield select(state => state.albaranManual.filtros)
      yield call(fetchAlbaranesManual, { filtros })
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchOnDeleteAlbaranManual() {
  yield takeLatest(actionTypes.ON_DELETE_ALBARAN_MANUAL, onDeleteAlbaranManual)
}

export function* imprimirAlbaranManual({ idAlbaranManual }) {
  try {    
    const datosPdfAlbaran = yield call(getImprimirDatosAlbaranService, idAlbaranManual)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfAlbaran.pdf)
    let name = i18n.t('ALBARANES.MODAL.IMPRESOS.DATOS_ALBARAN') + idAlbaranManual + "_manual.pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.error(error)
  } finally {
  }
}

export function* watchImprimirAlbaranManual() {
  yield takeLatest(actionTypes.IMPRIMIR_ALBARAN_MANUAL, imprimirAlbaranManual)
}

export function* abrirModalVacioAlbaranManual() {
  try {    
    let auth = yield select(state => state.auth)
    // let [initializeResult, { numeroAlbaran }] = yield all([      
    //   call(fetchNumeroAlbaranService)
    // ])
    let numeroAlbaran = null    
    let currentDate = dateTimeZones(auth.zonaHorariaString)
    yield put(initialize('ModalAlbaran', {
      numeroAlbaran,
      fechaCreacion: currentDate,
      estado: i18n.t('COMUN.COMBOS.' + translateEstadoAlbaranConstants[estadoAlbaranConstants["BORRADOR"]]),
      idEstado: estadoAlbaranConstants["BORRADOR"]
    }))    
    yield put(abrirModalVacioAlbaranManualSuccess())    
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchAbrirModalVacioAlbaranManual() {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_ALBARAN_MANUAL, abrirModalVacioAlbaranManual)
}

export function* abrirDetallesAlbaranManual({ idAlbaranManual }) {
  try {
    yield call(comboClienteSaga)
    let registroModificar = yield call(getAlbaranService, idAlbaranManual)
    let registrosFront = {
      idAlbaranManual,
      numeroAlbaran: registroModificar.numeroAlbaran,
      fechaCreacion: registroModificar.fechaCreacion,  
      cliente: registroModificar.cliente,
      cifCliente: registroModificar.cifCliente,
      direccionCliente: registroModificar.direccionCliente,
      localidadCliente: registroModificar.localidadCliente,
      provinciaCliente: registroModificar.provinciaCliente,   
      granja: registroModificar.granja,
      rega: registroModificar.rega,
      cifGranja: registroModificar.cifGranja,
      direccionGranja: registroModificar.direccionGranja,
      telefonoGranja: registroModificar.telefonoGranja,
      localidadGranja: registroModificar.localidadGranja,
      provinciaGranja: registroModificar.provinciaGranja,
      dosisAlbaran: registroModificar.lineasAlbaran      
    }    
    
    yield put(initialize('ModalAlbaran', registrosFront))    
    yield put(abrirDetallesAlbaranManualSuccess(registrosFront))    
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchAbrirDetallesAlbaranManual() {
  yield takeLatest(actionTypes.ABRIR_DETALLES_ALBARAN_MANUAL, abrirDetallesAlbaranManual)
}

export function* cerrarDetallesAlbaranManual() {
  try {
    yield all([
      call(comboDireccionClienteSaga, { idCliente: null }),
      call(comboDireccionClienteSecundariaSaga, { idClienteSecundario: null }),
      call(comboTransportistasClienteSaga, { idDireccionCliente: null }),
      call(comboVehiculosTransportistaDeterminadoSaga, { idTransportista: null }),
      call(comboConductoresTransportistaDeterminadoSaga, { idTransportista: null }),
      call(comboRutaTransportistaClienteSaga, { idDireccionCliente: null, idTransportista: null }),
      put(cerrarDetallesAlbaranManualSuccess())
    ])
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchCerrarDetallesAlbaranManual() {
  yield takeLatest(actionTypes.CERRAR_DETALLES_ALBARAN_MANUAL, cerrarDetallesAlbaranManual)
}

export function* editarAlbaranManual({ values, mostrarMensaje, location, callback }) {  
  try {
    let auth = yield select(state => state.auth)         
    if (mostrarMensaje === true) {
      let confirmedMensaje = true
      if (confirmedMensaje) {
        let state = yield select(state => state)
        let confirmed = true
       
        if (confirmed) {
          let preparedValues = {
            ...values
          }

          // Eliminar la propiedad idAlbaranDosis cuando esta contenga la palabra new.
          preparedValues.dosisAlbaran = state.albaranManual.data.dosisAlbaran.map((dosis) => {
            return {
              ...dosis,
              idAlbaranManualDosis: (/^new/.test(dosis.idAlbaranManualDosis) ? null : dosis.idAlbaranManualDosis)
            }
          })

          // Si se recibe el idAlbaran se hara una actualización de dicho albarán, sino se creara uno nuevo.
          let estadoPeticion
          if (values.idAlbaranManual) {
            estadoPeticion = yield call(submitEditarAlbaranService, preparedValues, state.auth.token)
          } else {
            estadoPeticion = yield call(submitNuevoAlbaranService, preparedValues, state.auth.token)
          }

          if (estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)) {
            yield put(openMensajeAlerta(values.idAlbaranManual ? 'modificacion' : 'insercion'))                                                         
            yield call(cerrarDetallesAlbaranManual) 
            yield call(fetchAlbaranesManual, { filtros: state.albaranManual.filtros })          
            if (callback) yield call(callback, (values.idAlbaranManual ? values.idAlbaranManual : estadoPeticion.idAlbaranManual))
            
          } else {
            yield put(openMensajeAlerta(values.idAlbaranManual ? 'no-modificacion' : 'no-insercion'))
          }
        }
      }
    } else {
      let state = yield select(state => state)
      let confirmed = true
      
      if (confirmed) {
        let preparedValues = {
          ...values
        }

        // Eliminar la propiedad idAlbaranDosis cuando esta contenga la palabra new.
        preparedValues.dosisAlbaran = state.albaranManual.data.dosisAlbaran.map((dosis) => {
          return {
            ...dosis,
            idAlbaranManualDosis: (/^new/.test(dosis.idAlbaranManualDosis) ? null : dosis.idAlbaranManualDosis)
          }
        })

        // Si se recibe el idAlbaran se hara una actualización de dicho albarán, sino se creara uno nuevo.
        let estadoPeticion
        if (values.idAlbaranManual) {
          estadoPeticion = yield call(submitEditarAlbaranService, preparedValues, state.auth.token)
        } else {
          estadoPeticion = yield call(submitNuevoAlbaranService, preparedValues, state.auth.token)
        }

        if (estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)) {
          yield put(openMensajeAlerta(values.idAlbaranManual ? 'modificacion' : 'insercion'))                  
          yield call(cerrarDetallesAlbaranManual)         
          yield call(fetchAlbaranesManual, { filtros: state.albaranManual.filtros })          
          if (callback) yield call(callback, (values.idAlbaranManual ? values.idAlbaranManual : estadoPeticion.idAlbaranManual))
        } else {
          yield put(openMensajeAlerta(values.idAlbaranManual ? 'no-modificacion' : 'no-insercion'))
        }
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchEditarAlbaranManual() {
  yield takeLatest(actionTypes.EDITAR_ALBARAN_MANUAL, editarAlbaranManual)
}

export function* abrirModalVacioAlbaranManualDosis() {
  try {    
    yield put(abrirModalVacioAlbaranManualDosisSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchAbrirModalVacioAlbaranDosisManual() {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_ALBARAN_DOSIS_MANUAL, abrirModalVacioAlbaranManualDosis)
}

export function* abrirDetallesAlbaranManualDosis({ row }) {  
  try {
    let state = yield select(state => state)
    let {
      fechaCreacion
    } = yield getFormValues('ModalAlbaran')(state)
    let filtros = {
      diasHasta: row.diasHastaExtraccion || 0,
      fecha: fechaCreacion,
      idRaza: row.idRaza,
      idLinea: row.idLinea,
      idNivelGenetico: row.idNivelGenetico,
      idTipoDosis: row.idTipoDosis
    }

    let numeroDosisRestantesInicial = row && row.numeroDosisRestantesInicial ? parseInt(row.numeroDosisRestantesInicial, 10) : 0
    let numeroDosisRestantes = row && row.numeroDosisRestantes ? parseInt(row.numeroDosisRestantes, 10) : 0
    let preparedValues = {
      idAlbaranDosis: row.idAlbaranDosis,
      idEnvasadoMultiDosis: row.idEnvasadoMultiDosis,
      dias: row.diasHastaExtraccion || 0,
      numeroDosisRestantesInicial: numeroDosisRestantesInicial,
      numeroDosisRestantes: numeroDosisRestantes - (row.dosisEnviadas || 0),
      dosisEnviadasInicial: row.dosisEnviadas || 0,
      dosisEnviadas: row.dosisEnviadas || 0,
      numeroDosisDisponibles: row.numeroDosisDisponibles || 0,
      numeroDosisReservadas: row.numeroDosisReservadas || 0,
      poolVerracoRadio: '' + row.tipoVerracoPool,
      datosOriginales: row,
      idPedido: row.idPedido,
      isVerracoPropio: row.isVerracoPropio ? true : false,
      rfidid: row.rfidid || null
    }

    // Si viene de un pedido se agregara a las llamadas el dato verraco. Además se mostrara en el formulario las dosis pedidas.
    if (row.dosisPedidas) {
      filtros.idVerraco = row.idVerraco
      preparedValues.dosisPedidas = row.dosisPedidas
      preparedValues.codVerraco = row.codVerraco || row.nombreVerracoPedido
    }

    if (!/^new/.test(row.idAlbaranDosis) && row.idEnvasadoMultiDosis) filtros.idEnvasadoMultiDosis = row.idEnvasadoMultiDosis

    if (state.albaranManual.data.idAlbaran) {
      filtros.idAlbaran = state.albaranManual.data.idAlbaran
    }

    yield all([
      comboTipoDosisSaga(),
      comboRazaSaga(),
      comboLineaGeneticaSaga(),
      comboNivelGeneticoActivoSaga(),
      ('' + row.tipoVerracoPool) === ('' + verracoSiNoConstants["VERRACO"]) ? comboVerracosExtraccionAlbaranSaga({ values: filtros }) : comboPoolsExtraccionAlbaranSaga({ values: filtros })
    ])

    // Obtener informacion extra de los combos.
    state = yield select(state => state)
    if (row.idTipoDosis) {
      preparedValues.tipoDosis = state.combos.comboTipoDosis.find((element) => {
        return element.value === row.idTipoDosis
      })
    }
    if (row.idRaza) {
      preparedValues.raza = state.combos.comboRaza.find((element) => {
        return element.value === row.idRaza
      })
    }
    if (row.idLinea) {
      preparedValues.linea = state.combos.comboLineaGenetica.find((element) => {
        return element.value === row.idLinea
      })
    }
    if (row.idNivelGenetico) {      
      preparedValues.nivelGenetico = state.combos.comboNivelGeneticoActivo.find((element) => {
        return element.value === row.idNivelGenetico
      })
    }

    // Filtrar los combos de dosis con el fin de evitar que se introduzcan dos registros del mismo envasado.
    let comboVerracosExtraccionAlbaran = state.combos.comboVerracosExtraccionAlbaran.filter(verracoExtraccionAlbaran => {
      return (
        (row.idEnvasadoMultiDosis === verracoExtraccionAlbaran.idEnvasadoMultiDosis) ||
        ((state.albaranManual.data.dosisAlbaran && state.albaranManual.data.dosisAlbaran.length) ? !state.albaranManual.data.dosisAlbaran.some(dosis => {
          return dosis.idEnvasadoMultiDosis === verracoExtraccionAlbaran.idEnvasadoMultiDosis
        }) : true)
      )
    })
    let comboPoolsExtraccionAlbaran = state.combos.comboPoolsExtraccionAlbaran.filter(poolExtraccionAlbaran => {
      return (
        (row.idEnvasadoMultiDosis === poolExtraccionAlbaran.idEnvasadoMultiDosis) ||
        ((state.albaranManual.data.dosisAlbaran && state.albaranManual.data.dosisAlbaran.length) ? !state.albaranManual.data.dosisAlbaran.some(dosis => {
          return dosis.idEnvasadoMultiDosis === poolExtraccionAlbaran.idEnvasadoMultiDosis
        }) : true)
      )
    })

    if (('' + row.tipoVerracoPool) === ('' + verracoSiNoConstants["VERRACO"])) {
      if (row.idEnvasadoMultiDosis) {
        preparedValues.verraco = comboVerracosExtraccionAlbaran.find((element) => {
          return element.idEnvasadoMultiDosis === row.idEnvasadoMultiDosis
        })
      } else if (row.idVerraco) {
        preparedValues.verraco = comboVerracosExtraccionAlbaran.find((element) => {
          return element.idVerraco === row.idVerraco
        })
      } else if (row.idVerracoPedido) {
        preparedValues.verraco = comboVerracosExtraccionAlbaran.find((element) => {
          return element.idVerraco === row.idVerracoPedido
        })
      }
      if (preparedValues.verraco) {
        preparedValues.numeroDosisRestantes = preparedValues.verraco.numeroDosisRestantes - (row.dosisEnviadas || 0)
        preparedValues.numeroDosisDisponibles = preparedValues.verraco.numeroDosisDisponibles
        preparedValues.numeroDosisReservadas = preparedValues.verraco.numeroDosisReservadas
      }
    } else if (('' + row.tipoVerracoPool) === ('' + verracoSiNoConstants["POOL"])) {
      preparedValues.pool = comboPoolsExtraccionAlbaran.find((element) => {
        return element.idEnvasadoMultiDosis === row.idEnvasadoMultiDosis
      })
      if (preparedValues.pool) {
        preparedValues.numeroDosisRestantes = preparedValues.pool.numeroDosisRestantes - (row.dosisEnviadas || 0)
        preparedValues.numeroDosisDisponibles = preparedValues.pool.numeroDosisDisponibles
        preparedValues.numeroDosisReservadas = preparedValues.pool.numeroDosisReservadas
      }
    }

    yield put(initialize('ModalAlbaranDosis', { ...preparedValues }))
    yield put(abrirDetallesAlbaranManualDosisSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchAbrirDetallesAlbaranDosisManual() {
  yield takeLatest(actionTypes.ABRIR_DETALLES_ALBARAN_DOSIS_MANUAL, abrirDetallesAlbaranManualDosis)
}

export function* cerrarDetallesAlbaranManualDosis() {
  try {
    yield put(cerrarDetallesAlbaranManualDosisSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchCerrarDetallesAlbaranDosisManual() {
  yield takeLatest(actionTypes.CERRAR_DETALLES_ALBARAN_DOSIS_MANUAL, cerrarDetallesAlbaranManualDosis)
}

export function* editarAlbaranManualDosis({ values, guardarYnuevo, onSuccessCallback, onErrorCallback }) {
  try {
    let albaran = yield select(state => state.albaranManual)
    let updatedDosisAlbaranList = albaran.data.dosisAlbaran.slice()    
    let dosisPedidas = values.dosisPedidas ? parseInt(values.dosisPedidas, 10) : 0
    let dosisEnviadas = values.dosisEnviadas ? parseInt(values.dosisEnviadas, 10) : 0      

    let confirmed = true      
    if (confirmed) {
      // Sumatorio por tipo de dosis.
      let newDosis = {
        idAlbaranManualDosis: values.idAlbaranManualDosis || 'new_' + updatedDosisAlbaranList.length, 
        raza: values.raza ? values.raza : '',
        linea: values.linea ? values.linea : '',         
        tipoDosis: values.tipoDosis ? values.tipoDosis : '',        
        dosisPedidas: values.dosisPedidas ? values.dosisPedidas : '',
        dosisEnviadasInicial: values.dosisEnviadas ? values.dosisEnviadas : '',
        dosisEnviadas: values.dosisEnviadas ? values.dosisEnviadas : ''        
      }        

      if (values.idAlbaranManualDosis) {
        let indexDosisExistente = updatedDosisAlbaranList.findIndex((element) => {
          return element.idAlbaranManualDosis === values.idAlbaranManualDosis
        })
        updatedDosisAlbaranList[indexDosisExistente] = newDosis
      } else {
        updatedDosisAlbaranList.push(newDosis)
      }
      
      //console.log('updatedDosisAlbaranList',updatedDosisAlbaranList)
      yield put(editarAlbaranManualDosisSuccess(updatedDosisAlbaranList))

      if (onSuccessCallback) yield onSuccessCallback()

      if (guardarYnuevo === false) {
        yield put(cerrarDetallesAlbaranManualDosisSuccess())
      } else {
        yield put(initialize('ModalAlbaranDosis'))
      }      
    } else {
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchEditarAlbaranDosisManual() {
  yield takeLatest(actionTypes.EDITAR_ALBARAN_DOSIS_MANUAL, editarAlbaranManualDosis)
}

export function* deleteModalAlbaranManualDosis({ idAlbaranManualDosis }) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, { modalType: 'onDelete' })
    if (confirmed) {

      const state = yield select(state => state)
      let list = state.albaranManual.data.dosisAlbaran.filter((row) => {
        return row.idAlbaranManualDosis !== idAlbaranManualDosis
      })
      yield put(openMensajeAlerta('eliminacion'))

      yield put(editarAlbaranManualDosisSuccess(list))     
    }
  } catch (error) { }
}
export function* watchDeleteModalAlbaranDosisManual() {
  yield takeLatest(actionTypes.DELETE_MODAL_ALBARAN_DOSIS_MANUAL, deleteModalAlbaranManualDosis)
}
