export default {
  FETCH_ALBARANES_MANUAL: 'FETCH_ALBARANES_MANUAL',
  FETCH_ALBARANES_MANUAL_SUCCESS: 'FETCH_ALBARANES_MANUAL_SUCCESS',  
  ON_DELETE_ALBARAN_MANUAL: 'ON_DELETE_ALBARAN_MANUAL',
  ABRIR_MODAL_VACIO_ALBARAN_MANUAL: "ABRIR_MODAL_VACIO_ALBARAN_MANUAL",
  ABRIR_MODAL_VACIO_ALBARAN_MANUAL_SUCCESS: "ABRIR_MODAL_VACIO_ALBARAN_MANUAL_SUCCESS",
  ABRIR_DETALLES_ALBARAN_MANUAL: "ABRIR_DETALLES_ALBARAN_MANUAL",
  ABRIR_DETALLES_ALBARAN_MANUAL_SUCCESS: "ABRIR_DETALLES_ALBARAN_MANUAL_SUCCESS",
  CERRAR_DETALLES_ALBARAN_MANUAL: "CERRAR_DETALLES_ALBARAN_MANUAL",
  CERRAR_DETALLES_ALBARAN_MANUAL_SUCCESS: "CERRAR_DETALLES_ALBARAN_MANUAL_SUCCESS",
  EDITAR_ALBARAN_MANUAL: "EDITAR_ALBARAN_MANUAL", 
  DELETE_MODAL_ALBARAN_DOSIS_MANUAL: "DELETE_MODAL_ALBARAN_DOSIS_MANUAL",  
  ABRIR_MODAL_VACIO_ALBARAN_DOSIS_MANUAL: "ABRIR_MODAL_VACIO_ALBARAN_DOSIS_MANUAL",
  ABRIR_MODAL_VACIO_ALBARAN_DOSIS_MANUAL_SUCCESS: "ABRIR_MODAL_VACIO_ALBARAN_DOSIS_MANUAL_SUCCESS",
  ABRIR_DETALLES_ALBARAN_DOSIS_MANUAL: "ABRIR_DETALLES_ALBARAN_DOSIS_MANUAL",
  ABRIR_DETALLES_ALBARAN_DOSIS_MANUAL_SUCCESS: "ABRIR_DETALLES_ALBARAN_DOSIS_MANUAL_SUCCESS",
  CERRAR_DETALLES_ALBARAN_DOSIS_MANUAL: "CERRAR_DETALLES_ALBARAN_DOSIS_MANUAL",
  CERRAR_DETALLES_ALBARAN_DOSIS_MANUAL_SUCCESS: "CERRAR_DETALLES_ALBARAN_DOSIS_MANUAL_SUCCESS",
  EDITAR_ALBARAN_DOSIS_MANUAL: "EDITAR_ALBARAN_DOSIS_MANUAL",
  EDITAR_ALBARAN_DOSIS_MANUAL_SUCCESS: "EDITAR_ALBARAN_DOSIS_MANUAL_SUCCESS",
  IMPRIMIR_ALBARAN_MANUAL: "IMPRIMIR_ALBARAN_MANUAL", 
}