import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import FormGroupSubtitle from '../comun/form/FormGroupSubtitle'
import InputRadioButton from '../comun/form/InputRadioButton'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import { required } from '../../util/validationFunctions'
import {verracoSiNo as verracoSiNoConstants, translateVerracoSiNo as translateVerracoSiNoConstants} from '../../constants/seguimiento'

class ModalAlbaranDosis extends Component {
  constructor () {
    super()    
    this.state = {      
    }
  }

  componentDidMount () {
      
  }
  
  componentDidUpdate (prevProps) {
    
  }  

  editarAlbaranDosis (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.editarAlbaranManualDosis(values, false, () => {
        resolve()
      }, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevo (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.editarAlbaranManualDosis(values, true, () => {
        resolve()
      }, reject)
    })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, showModalAlbaranDosis, formModalAlbaranDosis, formModalAlbaran, submitting, dosisAlbaran,
      actions: {cerrarDetallesAlbaranManualDosis}
    } = this.props
    const tKey = 'ALBARANES.MODAL.MODAL_MULTIDOSIS.'    

    return (
      <Modal show={showModalAlbaranDosis} onHide={cerrarDetallesAlbaranManualDosis} backdrop="static" className="modal-albaran-venta">
      <form className="form-albaran">
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tKey + (formModalAlbaranDosis && formModalAlbaranDosis.idAlbaranManualDosis ? 'TITLE_MODIFICAR' : 'TITLE_NUEVO'))}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={3}
              id="raza"
              name="raza"
              controlLabel={t(tKey + 'FORM.RAZA')}
              component={InputText}
              disabled={false}
              maxLength={true}
              valorMaxLength={150}
            />
            <Field
              colSm={3}
              id="linea"
              name="linea"
              controlLabel={t(tKey + 'FORM.LINEA_GENETICA')}
              component={InputText}
              disabled={false}
              maxLength={true}
              valorMaxLength={150}
            />
            <Field
              colSm={3}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t(tKey + 'FORM.TIPO_DOSIS')}
              component={InputText}
              disabled={false}
              maxLength={true}
              valorMaxLength={150}
            />
            <Field
              colSm={3}
              id="dosisPedidas"
              name="dosisPedidas"
              controlLabel={t(tKey + 'FORM.DOSIS_PEDIDAS')}
              component={InputText}
              disabled={false}
              maxLength={true}
              valorMaxLength={150}
            />
            <Field
              colSm={3}
              id="dosisEnviadas"
              name="dosisEnviadas"
              controlLabel={t(tKey + 'FORM.DOSIS_ENVIADAS')}
              component={InputText}
              disabled={false}
              maxLength={true}
              valorMaxLength={150}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!(formModalAlbaranDosis && formModalAlbaranDosis.idAlbaranManualDosis) && //solo cuando es nuevo
            <Button type="button" onClick={!submitting ? handleSubmit(this.guardarYnuevo.bind(this)) : null} className="btn btn-primary">{t(tKey + 'BUTTONS.GUARDAR_Y_NUEVO')}</Button>
          }
          <Button type="button" onClick={!submitting ? handleSubmit(this.editarAlbaranDosis.bind(this)) : null}  className="btn btn-primary">{t(tKey + 'BUTTONS.GUARDAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={cerrarDetallesAlbaranManualDosis}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAlbaranDosis',
})(ModalAlbaranDosis))