import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import { yesNoModal } from '../../actions/common'
import {cerrarDetallesAlbaranManualDosis, editarAlbaranManualDosis} from '../../actions/albaranManual/albaranManual'
import ModalAlbaranDosis from '../../components/albaranManual/ModalAlbaranDosis'

export function mapStateToProps (state) {
  return {
    ...state.albaranManual.data,
    formModalAlbaran: getFormValues('ModalAlbaran')(state),
    formModalAlbaranDosis: getFormValues('ModalAlbaranDosis')(state),
    auth: state.auth    
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarDetallesAlbaranManualDosis,
      editarAlbaranManualDosis,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAlbaranDosis))