export default {
  FETCH_DILUCION: 'FETCH_DILUCION',
  FETCH_DILUCION_SUCCESS: 'FETCH_DILUCION_SUCCESS',
  ABRIR_DETALLES: 'ABRIR_DETALLES',
  ABRIR_DETALLES_SUCCESS: 'ABRIR_DETALLES_SUCCESS',
  CERRAR_DETALLES: 'CERRAR_DETALLES',
  CERRAR_DETALLES_SUCCESS: 'CERRAR_DETALLES_SUCCESS',
  EDITAR_DILUCION: 'EDITAR_DILUCION',
  FETCH_FILTRAR_DILUCION: 'FETCH_FILTRAR_DILUCION',
  FETCH_FILTRAR_DILUCION_SUCCESS: 'FETCH_FILTRAR_DILUCION_SUCCESS',
  CREATE_CSV_DILUCION: 'CREATE_CSV_DILUCION',
  CREATE_CSV_DILUCION_SUCCESS: 'CREATE_CSV_DILUCION_SUCCESS',
  IMPRIMIR_PDF_DILUCION: 'IMPRIMIR_PDF_DILUCION',
  IMPRIMIR_PDF_DILUCION_SUCCESS: 'IMPRIMIR_PDF_DILUCION_SUCCESS',
  PREPARAR_MAQUINA_DILUCION: 'PREPARAR_MAQUINA_DILUCION',
  CANCELAR_MAQUINA_DILUCION: 'CANCELAR_MAQUINA_DILUCION',
  ACTUALIZAR_ESTADO_MAQUINA_DILUCION: 'ACTUALIZAR_ESTADO_MAQUINA_DILUCION',
  SELECCIONAR_TODOS_DILUCION: 'SELECCIONAR_TODOS_DILUCION',
  DESELECCIONAR_TODOS_DILUCION: 'DESELECCIONAR_TODOS_DILUCION',
  DELETE_SELECCION_DILUCION: 'DELETE_SELECCION_DILUCION',
  ON_DELETE_DILUCION: 'ON_DELETE_DILUCION',
  GET_REFRACTOMETRO: 'GET_REFRACTOMETRO',
  GET_REFRACTOMETRO_ERR : 'GET_REFRACTOMETRO_ERR'
}