import {call, put, takeLatest, take, race, cancel, select} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import {initialize, change} from 'redux-form'
import i18n from '../../i18n';

import {modulos as modulosConstant} from '../../constants/modulos'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import {estadoMaquinaDilucion, translateEstadoMaquinaDilucion} from '../../constants/maquinas'
import actionTypes from '../../constants/actions/dilucion/dilucion'
import { fetchDilucionSuccess, abrirDetallesSuccess, cerrarDetallesSuccess, fetchFiltrarDilucionSuccess, crearCsvDilucionSuccess, imprimirPdfDilucionSuccess, actualizarEstadoMaquinaDilucion, getRefractometroErr } from '../../actions/dilucion/dilucion'
import { openModalTrazabilidadMaterial, comprobarStockTrazabilidadSuccess } from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta, openMensajeAlertaCustomText} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getDilucionList from '../../services/dilucion/getDilucionList'
import getDilucion from '../../services/dilucion/getDilucion'
import editDilucion from '../../services/dilucion/editDilucion'
import comprobarPrimeraDilucionDelDiaService from '../../services/dilucion/comprobarPrimeraDilucionDelDia'
import prepararMaquinaDilucionService from '../../services/dilucion/prepararMaquinaDilucion'
import comprobarEstadoMaquinaDilucionService from '../../services/dilucion/comprobarEstadoMaquinaDilucion'
import getFiltrarDilucion from '../../services/dilucion/getFiltrarDilucion'
import getCrearCsvDilucion from '../../services/dilucion/getCrearCsvDilucion'
import getImprimirPdfDilucion from '../../services/dilucion/getImprimirPdfDilucion'
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import getDeleteSeleccionDilucion from '../../services/dilucion/getDeleteSeleccionDilucion'
import getDeleteDilucion from '../../services/dilucion/getDeleteDilucion'
import { obtenerNotificaconesUsuarioSuccess } from '../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../services/notificaciones/getNotificaciones'
import getRefractometro from '../../services/dilucion/getRefractometro'

export function * fetchDilucion () {
  try {
    const list = yield call(getDilucionList, null)
    yield put(fetchDilucionSuccess(list))
  } catch (error) {}
}
export function * watchFetchDilucion () {
  yield takeLatest(actionTypes.FETCH_DILUCION, fetchDilucion)
}

export function * abrirDetalles ({idDilucion}) {
  try {
    let auth = yield select(state => state.auth)
    const data = yield call(getDilucion, idDilucion, null)
    if (data.idOperario) data.idOperario = {value: data.idOperario, label: data.nombreOperario}
    if (data.idMaquina) {
      data.idMaquina = {value: data.idMaquina, label: data.nombreMaquina}
    } else {
      let comboMaquina = yield select(state => state.combos.comboMaquina)
      if (comboMaquina[0] && comboMaquina.length === 1) {
        data.idMaquina = comboMaquina[0]
      }
    }
    if (data.idTipoDiluyente) data.idTipoDiluyente = {value: data.idTipoDiluyente, label: data.nombreDiluyente}
    yield put(abrirDetallesSuccess(data))
    yield put(initialize('ModalDilucion', data))

    if (auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD)) {
      yield put(comprobarStockTrazabilidadSuccess({[categoriasEscandallos.DILUCION]: data.stockInsuficiente}))
      if (data.idTipoDosis) {
        yield put(openModalTrazabilidadMaterial({
          idTipoDosis: data.idTipoDosis,
          idCategoria: categoriasEscandallos.DILUCION,
          idAnalisis: data.idAnalisis,
          ultimaModificacion: data.ultimaModificacion,
          disabled: data.envasado,
          openModal: false,
          showStockColumns: !(data.trazabilidad)
        }))
      }
    }
  } catch (error) {}
}
export function * watchAbrirDetalles () {
  yield takeLatest(actionTypes.ABRIR_DETALLES, abrirDetalles)
}

export function * editarDilucion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let estadoPeticion = yield call(editDilucion, {...values, guardarUltimaModificacion: true}, auth.token)
    yield put(cerrarDetallesSuccess())

    if (estadoPeticion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }

    const val = yield select(state => state.dilucion.filtros)
    const list = yield call(getFiltrarDilucion, val, auth.token)
    yield put(fetchDilucionSuccess(list))
    yield put(fetchFiltrarDilucionSuccess(val))

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {}
}
export function * watchEditarDilucion () {
  yield takeLatest(actionTypes.EDITAR_DILUCION, editarDilucion)
}

export function * racePrepararMaquinaDilucion ({values}) {
  const controller = new AbortController();
  const signal = controller.signal;

  const {finished} = yield race({
    finished: call(prepararMaquinaDilucion, {values, signal}),
    cancelled: take(actionTypes.CANCELAR_MAQUINA_DILUCION)
  })

  if (!finished) {
    controller.abort();
    yield put(actualizarEstadoMaquinaDilucion(''))
  }
}
export function * watchPrepararMaquinaDilucion () {
  yield takeLatest(actionTypes.PREPARAR_MAQUINA_DILUCION, racePrepararMaquinaDilucion)
}

export function * prepararMaquinaDilucion ({values, signal}) {
  try {
    let auth = yield select(state => state.auth)

    // Comprobar si es la primera dilucion del día y en caso afirmativo mostrar mensaje de advertencia sobre el 'modo agua'.
    let esPrimeraDilucionDelDia = yield call(comprobarPrimeraDilucionDelDiaService)
    if (esPrimeraDilucionDelDia) {
      yield call(yesNoModalSaga, {modalType: 'comprobarModoAguaDeshabilitado'})
    }

    yield put(actualizarEstadoMaquinaDilucion(estadoMaquinaDilucion['PROCESANDO_DILUCION']))
    let {maquinaPreparada, conexionVPN, error, maquinaEnUso} = yield call(prepararMaquinaDilucionService, {...values, guardarUltimaModificacion: true, noPartirDilucion: true}, auth.token, signal)
    let codigoEstadoMaquina = maquinaPreparada ? estadoMaquinaDilucion['PREPARADA'] : conexionVPN === false ? estadoMaquinaDilucion['CONEXION_VPN_NO_ESTABLECIDA'] : maquinaEnUso ? estadoMaquinaDilucion['EN_USO'] : estadoMaquinaDilucion['NO_PREPARADA']
    let mensajeEstadoMaquina

    if (codigoEstadoMaquina === estadoMaquinaDilucion['NO_PREPARADA'] || codigoEstadoMaquina === estadoMaquinaDilucion['EN_USO'] ||codigoEstadoMaquina === estadoMaquinaDilucion['CONEXION_VPN_NO_ESTABLECIDA']) {
      if (error && error.codigo) {
        codigoEstadoMaquina = error.codigo
      }
      yield put(actualizarEstadoMaquinaDilucion(codigoEstadoMaquina))
      mensajeEstadoMaquina = i18n.t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaDilucion[codigoEstadoMaquina])
      yield call(yesNoModalSaga, {modalType: 'dynamicMessage', message: mensajeEstadoMaquina})
      yield put(change('ModalDilucion', 'diluido', false))
    } else {
      codigoEstadoMaquina = null
      yield put(change('ModalDilucion', 'diluido', true))
      yield put(actualizarEstadoMaquinaDilucion(estadoMaquinaDilucion['DILUYENDO']))

      // Funcionalidad que pregunta constantemente el estado de la máquina.
      // while ((!codigoEstadoMaquina || codigoEstadoMaquina === estadoMaquinaDilucion['DILUYENDO'] || codigoEstadoMaquina === estadoMaquinaDilucion['PREPARADA_PARA_DILUIR'])) {
      //   codigoEstadoMaquina = yield call(comprobarEstadoMaquinaDilucionService, values.idMaquina.value, values.idAnalisis, signal)
      //   codigoEstadoMaquina = codigoEstadoMaquina.codigo ? codigoEstadoMaquina.codigo : ''
      //   yield put(actualizarEstadoMaquinaDilucion(codigoEstadoMaquina))
      //
      //   mensajeEstadoMaquina = i18n.t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaDilucion[codigoEstadoMaquina])
      //   if (codigoEstadoMaquina === estadoMaquinaDilucion['EN_ESPERA_RETIRAR_RECIPIENTE']) {
      //     yield call(yesNoModalSaga, {
      //       modalType: 'dynamicMessage',
      //       message: i18n.t('COMUN.ESTADO_MAQUINA.DILUCION_COMPLETADA'),
      //       subMessage: mensajeEstadoMaquina
      //     })
      //
      //     // Una vez completado el proceso sin errores se cerrara el formulario y se recargara el listado.
      //     yield put(cerrarDetallesSuccess())
      //     const val = yield select(state => state.dilucion.filtros)
      //     const list = yield call(getFiltrarDilucion, val, auth.token)
      //     yield put(fetchDilucionSuccess(list))
      //     yield put(fetchFiltrarDilucionSuccess(val))
      //   } else if ((codigoEstadoMaquina !== estadoMaquinaDilucion['DILUYENDO'] && codigoEstadoMaquina !== estadoMaquinaDilucion['PREPARADA_PARA_DILUIR'])) {
      //     yield call(yesNoModalSaga, {
      //       modalType: 'dynamicMessage',
      //       message: i18n.t('COMUN.ESTADO_MAQUINA.DILUCION_NO_COMPLETADA'),
      //       subMessage: mensajeEstadoMaquina
      //     })
      //   }
      //   yield delay(1000);
      // }
      //
      // if (codigoEstadoMaquina !== estadoMaquinaDilucion['EN_ESPERA_RETIRAR_RECIPIENTE']) {
      //   yield put(actualizarEstadoMaquinaDilucion(estadoMaquinaDilucion['FINALIZADO']))
      // }
    }
  } catch (error) {
  } finally {
    // console.log('finally prepararMaquinaDilucion...')
  }
  return true
}
export function * getRefractometroValues () {
  try {
    const datosRefractometro = yield call(getRefractometro)
    console.log(datosRefractometro.gradosBrix)
    var error = true
    if(datosRefractometro.gradosBrix)
    {
      yield put(change('ModalDilucion', 'Temperatura', datosRefractometro.temperatura))
      yield put(change('ModalDilucion', 'LecturaRefractometro', datosRefractometro.gradosBrix))
      error = false
    }
    yield put(getRefractometroErr(error))

  } catch (error) {}
}
export function * watchGetRefractometro () {
  yield takeLatest(actionTypes.GET_REFRACTOMETRO, getRefractometroValues)
}

export function * cerrarDetalles () {
  try {
    yield put(cerrarDetallesSuccess())
  } catch (error) {}
}
export function * watchCerrarDetalles () {
  yield takeLatest(actionTypes.CERRAR_DETALLES, cerrarDetalles)
}

export function * fetchFiltrarDilucion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarDilucion, values, auth.token)
    yield put(fetchDilucionSuccess(list))
    yield put(fetchFiltrarDilucionSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarDilucion () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_DILUCION, fetchFiltrarDilucion)
}

export function * crearCsvDilucion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelDilucion = yield call(getCrearCsvDilucion, list, auth.token)
    yield put(crearCsvDilucionSuccess(datosExcelDilucion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvDilucion () {
  yield takeLatest(actionTypes.CREATE_CSV_DILUCION, crearCsvDilucion)
}

export function * imprimirPdfDilucion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfDilucion = yield call(getImprimirPdfDilucion, list, auth.token)
    yield put(imprimirPdfDilucionSuccess(datosPdfDilucion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfDilucion () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_DILUCION, imprimirPdfDilucion)
}

export function * deleteSeleccionDilucion ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const onDeleteseleccion = yield call(getDeleteSeleccionDilucion, list, auth.token)
      if (onDeleteseleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      const val = yield select(state => state.dilucion.filtros)
      const lista = yield call(getFiltrarDilucion, val, auth.token)
      yield put(fetchDilucionSuccess(lista))
      yield put(fetchFiltrarDilucionSuccess(val))
      yield put(initialize('FiltrosDilucion', val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionDilucion () {
  yield takeLatest(actionTypes.DELETE_SELECCION_DILUCION, deleteSeleccionDilucion)
}

export function * onDeleteDilucion ({idDilucion}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const onDelete = yield call(getDeleteDilucion, idDilucion, null)
      if (onDelete.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (onDelete.eliminado === false) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }

      const val = yield select(state => state.dilucion.filtros)
      const lista = yield call(getFiltrarDilucion, val, auth.token)
      yield put(fetchDilucionSuccess(lista))
      yield put(fetchFiltrarDilucionSuccess(val))
      yield put(initialize('FiltrosDilucion', val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteDilucion () {
  yield takeLatest(actionTypes.ON_DELETE_DILUCION, onDeleteDilucion)
}
