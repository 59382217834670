import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal } from '../../actions/common'
import {fetchAlbaranesManual, abrirDetallesAlbaranManual, onDeleteAlbaranManual, imprimirAlbaranManual} from '../../actions/albaranManual/albaranManual'
import AlbaranManual from '../../components/albaranManual/AlbaranManual'

export function mapStateToProps (state) {
  return {
    ...state.albaranManual,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAlbaranesManual,
      abrirDetallesAlbaranManual,      
      onDeleteAlbaranManual,
      imprimirAlbaranManual,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AlbaranManual))