import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import FormGroupSubtitle from '../comun/form/FormGroupSubtitle'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosModalAlbaranPage from '../../containers/albaranManual/FiltrosModalAlbaranPage'
import ModalAlbaranDosisPage from '../../containers/albaranManual/ModalAlbaranDosisPage'
import {estadoAlbaran as estadoAlbaranConstants} from '../../constants/albaran'
import {number_formatter} from '../../util/formatFunctions'
import { required } from '../../util/validationFunctions'
import './Albaran.scss'
import { RemoveRedEye } from '@material-ui/icons'
import ModalDetallePedidoPage from '../../containers/pedidosVenta/ModalDetallePedidoPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import { abrirDetallesAlbaranManual } from '../../actions/albaranManual/albaranManual'

class ModalAlbaran extends Component {
  constructor () {
    super()
    this.state = {
      idPedido: null,
      hayCliente: null,
      hayGranja: null
    }
      
  }

  editarAlbaran (values) {    
    values.dosisAlbaran = new Array();
    Object.keys(this.props.data.dosisAlbaran).map((key) => {         
      if(values.dosisAlbaran){                      
        values.dosisAlbaran[key] = this.props.data.dosisAlbaran[key]            
      }
    }) 
    let location = this.props.location  
    this.props.actions.editarAlbaranManual(values, true, location)      
  }

  imprimirDatosAlbaranManual (values) {       
    this.props.actions.imprimirAlbaranManual(values.idAlbaranManual)  
  }  

  componentDidUpdate (prevProps) {
    
  }

  componentDidMount () {
      
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, handleSubmit, list, showModalAlbaran, data, hasButtonChangeRecord = true,
      auth: {separadorMil, separadorDec},
      actions: {
        cerrarDetallesAlbaranManual, deleteModalAlbaranManualDosis, abrirDetallesManualAlbaran, abrirDetallesAlbaranManualDosis
      }
    } = this.props
    const idEstado = data.idEstado || estadoAlbaranConstants["BORRADOR"]

    /* console.log('panelControl',this.props.panelControl.boxRfid) */

    const tKey = 'ALBARANES.MODAL.'
    const idElemento = 'idAlbaranDosis'
    const table = {
      id: 'albaranExtraccionesTable',
      multiHeader: false,
      hasCheckbox: false,
      idElemento,
      hasOptions: false,
      noBlockOptions: true,
      mostrarModificar: false,
      onEdit: (row) => { abrirDetallesAlbaranManualDosis(row) },
      mostrarDuplicar: false,
      mostrarEliminar: true,
      onDelete: (row) => { deleteModalAlbaranManualDosis(row.idAlbaranManualDosis) },
      mostrarCambioEstado: false,
      columns: [        
        {id: 'raza', name: t(tKey + 'COLUMNS.RAZA')},
        {id: 'linea', name: t(tKey + 'COLUMNS.LINEA_GENETICA')},        
        {id: 'tipoDosis', name: t(tKey + 'COLUMNS.TIPO_DOSIS')},
        {id: 'dosisPedidas', name: t(tKey + 'COLUMNS.DOSIS_PEDIDAS'), type: 'numero', numDecimales: 0},
        {id: 'dosisEnviadas', name: t(tKey + 'COLUMNS.DOSIS_ENVIADAS'), type: 'numero', numDecimales: 0},    
      ],
      rows: data.dosisAlbaran,
      filtros: <FiltrosModalAlbaranPage idElemento={idElemento}  hayCliente={this.state.hayCliente} hayGranja={this.state.hayGranja} />,
      showModal: this.props.showModalAlbaranDosis,
      tablaModal: true,
      idBlockOptions: 2,
      initialValues: {},
      leyenda: [],
      colorearRow: (row) => {
        let classRow = ''
        if (row.tipo === 'pool') {
          classRow = 'stock-dosis-pool'
        }else if (row.tipo === 'verraco') {
          classRow = 'stock-dosis-verraco'
        }
        return classRow
      }
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row[idElemento]] = data.checkedFiltrosAlbaranDosis
        }
      }
    )

    return (
      <Modal show={showModalAlbaran} onHide={cerrarDetallesAlbaranManual} dialogClassName="xl" backdrop="static">        
        <ModalAlbaranDosisPage />
        <ModalDetallePedidoPage />
        <form className="form-albaran" onSubmit={handleSubmit(this.editarAlbaran.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t(tKey + (data && data.idAlbaran ? 'TITLE_MODIFICAR' : data && data.idAlbaranDuplicado ? 'TITLE_DUPLICAR' : 'TITLE_NUEVO'))}
            </Modal.Title>
            {data && data.idAlbaran && hasButtonChangeRecord && (
              <ButtonChangeRecord list={list.albaranes} idElemento="idAlbaran" currentId={data.idAlbaran} getNextRecord={abrirDetallesAlbaranManual} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="numeroAlbaran"
                name="numeroAlbaran"
                controlLabel={t(tKey + 'FORM.NUM_ALBARAN')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />                                        
              <Field
                colSm={3}
                id="fechaCreacion"
                name="fechaCreacion"
                controlLabel={t(tKey + 'FORM.FECHA_CREACION')}
                component={InputDatePicker}
                disabled={idEstado === estadoAlbaranConstants["REALIZADO"]}
              />             
            </Row>
            <Row>
              <Field
                colSm={3}
                id="cliente"
                name="cliente"
                controlLabel={t(tKey + 'FORM.CLIENTE')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              /> 
              <Field
                colSm={3}
                id="cifCliente"
                name="cifCliente"
                controlLabel={t('EMPRESA.CIF')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />   
              <Field
                colSm={3}
                id="direccionCliente"
                name="direccionCliente"
                controlLabel={t('EMPRESA.DIRECCION')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />
              <Field
                colSm={3}
                id="localidadCliente"
                name="localidadCliente"
                controlLabel={t('EMPRESA.LOCALIDAD')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />
              <Field
                colSm={3}
                id="provinciaCliente"
                name="provinciaCliente"
                controlLabel={t('EMPRESA.PROVINCIA')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />                         
            </Row>
            <Row>
              <Field
                colSm={3}
                id="granja"
                name="granja"
                controlLabel={t('RUTA.MODAL.TABLA.GRANJA')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              /> 
              <Field
                colSm={3}
                id="rega"
                name="rega"
                controlLabel={t('EMPRESA.CODIGO_REGA')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />   
              <Field
                colSm={3}
                id="cifGranja"
                name="cifGranja"
                controlLabel={t('EMPRESA.CIF')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />
              <Field
                colSm={3}
                id="direccionGranja"
                name="direccionGranja"
                controlLabel={t('EMPRESA.DIRECCION')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />
              <Field
                colSm={3}
                id="telefonoGranja"
                name="telefonoGranja"
                controlLabel={t('EMPRESA.TELEFONO')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />
              <Field
                colSm={3}
                id="localidadGranja"
                name="localidadGranja"
                controlLabel={t('EMPRESA.LOCALIDAD')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />
              <Field
                colSm={3}
                id="provinciaGranja"
                name="provinciaGranja"
                controlLabel={t('EMPRESA.PROVINCIA')}
                component={InputText}
                disabled={false}
                maxLength={true}
                valorMaxLength={150}
              />            
            </Row>                                                
            <hr />
            <SimpleTablePage {...table} />
            <hr />                        
          </Modal.Body>
          <Modal.Footer>
            {data.idAlbaranManual && [
              <Button key="boton-imprimir-datos-albaran" type="button" className="btn btn-primary" onClick={handleSubmit(this.imprimirDatosAlbaranManual.bind(this))}>{t(tKey + 'BUTTONS.IMPRIMIR_ALBARAN')}</Button>,              
            ]}
            <Button
              type="submit"
              className={'btn btn-primary' + (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2 ? ' disabled' : '')}
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2)}
            >{t(tKey + 'BUTTONS.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={cerrarDetallesAlbaranManual}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAlbaran',
})(ModalAlbaran))