import actionTypes from '../../constants/actions/albaranManual/albaranManual'

export function initialState () {
  return {
    list: [],
    checkedFiltrosAlbaran: false,
    filtros:{},
    showModalAlbaran: false,
    data: {
      dosisAlbaran: [],
      totalesPorDosis: {total: {tipoDosis: 'total', dosisPedidas: 0, dosisEnviadas: 0, dosisPorServir: 0}},
      checkedFiltrosAlbaranDosis: false,
      showModalAlbaranDosis: false,
      showModalAlbaranRfidValidar: false,
    },
    dataDuplicar: [],
    lecturasDosisAlbaranRFID: []
  }
}

export function fetchAlbaranesManualSuccess (state, {list, filtros}) {  
  return {
    ...state,
    list,
    filtros
  }
}

export function abrirModalVacioAlbaranManualSuccess (state) {  
  return {
    ...state,
    showModalAlbaran: true
  }
}

export function abrirDetallesAlbaranManualSuccess (state, {data}) {
  return {
    ...state,
    showModalAlbaran: true,
    data: {
      ...state.data,
      ...data,
      totalesPorDosis: state.data.totalesPorDosis,
    }
  }
}

export function cerrarDetallesAlbaranManualSuccess (state) {
  return {
    ...state,
    showModalAlbaran: false,
    data: {
      ...initialState().data
    }
  }
}

export function abrirModalVacioAlbaranManualDosisSuccess (state) {
  console.log('entrareducer2')
  return {
    ...state,
    data: {
      ...state.data,
      showModalAlbaranDosis: true
    }
  }
}

export function abrirDetallesAlbaranManualDosisSuccess (state) {
  return {
    ...state,
    data: {
      ...state.data,
      showModalAlbaranDosis: true
    }
  }
}

export function cerrarDetallesAlbaranManualDosisSuccess (state) {
  return {
    ...state,
    data: {
      ...state.data,
      showModalAlbaranDosis: false      
    }
  }
}

export function editarAlbaranManualDosisSuccess (state, {list}) {  
  return {
    ...state,
    lecturasDosisAlbaranRFID: list,
    data: {
      ...state.data,      
      dosisAlbaran: list,      
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_ALBARANES_MANUAL_SUCCESS:
      return fetchAlbaranesManualSuccess(state, action)
    case actionTypes.ABRIR_MODAL_VACIO_ALBARAN_MANUAL_SUCCESS:
      return abrirModalVacioAlbaranManualSuccess(state, action)
    case actionTypes.ABRIR_DETALLES_ALBARAN_MANUAL_SUCCESS:
      return abrirDetallesAlbaranManualSuccess(state, action)
    case actionTypes.CERRAR_DETALLES_ALBARAN_MANUAL_SUCCESS:
      return cerrarDetallesAlbaranManualSuccess(state, action)   
    case actionTypes.ABRIR_MODAL_VACIO_ALBARAN_DOSIS_MANUAL_SUCCESS:
      return abrirModalVacioAlbaranManualDosisSuccess(state, action)
    case actionTypes.ABRIR_DETALLES_ALBARAN_DOSIS_MANUAL_SUCCESS:
      return abrirDetallesAlbaranManualDosisSuccess(state, action)
    case actionTypes.CERRAR_DETALLES_ALBARAN_DOSIS_MANUAL_SUCCESS:
      return cerrarDetallesAlbaranManualDosisSuccess(state, action)
    case actionTypes.EDITAR_ALBARAN_DOSIS_MANUAL_SUCCESS:
      return editarAlbaranManualDosisSuccess(state, action)    
    default:
      return state
  }
}