import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalDilucionPage from '../../containers/dilucion/ModalDilucionPage'
import FiltrosDilucionPage from '../../containers/dilucion/FiltrosDilucionPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './Dilucion.scss'
import { date_parser, dateTimeZones } from '../../util/formatFunctions'
import SimplePage from '../../containers/page/SimplePage'
import { Row, Col, Modal} from 'react-bootstrap'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'

class Dilucion extends Component {
  constructor () {
    super()
    this.state = {
      init: false,
      listNoDiluido: [],
      listDiluido: []
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.EXTRACCIONES.DILUCION') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    let estadoLabel = this.props.t(`COMUN.COMBOS.ESTADO_DILUCION.${this.props.combos.comboDiluido[1].label}`)
    //this.props.actions.fetchFiltrarDilucion({estado: {...this.props.combos.comboDiluido[1], label: estadoLabel}, fechaEntrada: fechaActual, desde: fechaActual})
    this.props.actions.fetchFiltrarDilucion({fechaEntrada: fechaActual, desde: fechaActual})
  }

  componentDidUpdate (prevProps) {
    if(this.props.dilucion.list !== prevProps.dilucion.list && this.props.dilucion.list.length > 0 && this.state.init == false){
      this.setState({ 
        //init: true,
        listNoDiluido: this.props.dilucion.list.filter(d => d.diluido === false),
        listDiluido: this.props.dilucion.list.filter(d => d.diluido === true),
      })
    }
    if(this.state.init == true) this.state.init = false;
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      dilucion: { list, checkedFiltrosDilucion },
      actions: {fetchDilucionSuccess, abrirDetalles, onDeleteDilucion}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idDilucion
    )
    //let listNoDiluido = list.filter(d => d.diluido === false);
    //let listDiluido = list.filter(d => d.diluido === true);

    let listNoDiluido = this.state.listNoDiluido
    let listDiluido = this.state.listDiluido

    const tableNoDiluido = {
      id: 'noDilucionTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: true,
      columns: [
        {id: 'codVerraco', name: t('EXTRACCIONES.DILUCION.TABLA.COD_VERRACO')},
        {id: 'codigo', name: t('EXTRACCIONES.DILUCION.TABLA.CODIGO')},
        {id: 'fecha', name: t('EXTRACCIONES.DILUCION.TABLA.FECHA'), type: 'fecha'},
        {id: 'volumenExtraccion', name: t('EXTRACCIONES.DILUCION.TABLA.VOLUMEN_ESTRACCION'), type: 'numero', numDecimales: 0},
        {id: 'volumenDiluyente', name: t('EXTRACCIONES.DILUCION.TABLA.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0},
        {id: 'nombreDiluyente', name: t('EXTRACCIONES.DILUCION.TABLA.NOMBRE_DILUYENTE')},
        //{id: 'TipoDosis', name: t('EXTRACCIONES.DILUCION.TABLA.TIPO_DOSIS')},
        {id: 'numeroDosis', name: t('EXTRACCIONES.DILUCION.TABLA.NUMERO_DOSIS'), type: 'numero', numDecimales: 0}
        //{id: 'observacionesDilucion', name: t('EXTRACCIONES.DILUCION.TABLA.OBSERVACIONES')}
      ],
      rows: listNoDiluido,
      filtros: false,
      mostrarVerDetalle: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      onEdit: (row) => {abrirDetalles(row.idDilucion); this.state.init = false;},
      onSort: (rows) => {this.state.init = true; fetchDilucionSuccess(rows)},
      /*onDelete: (row) => onDeleteDilucion(row.idDilucion),*/
      initialValues: {},
      leyenda: [
        {name: t('EXTRACCIONES.DILUCION.LEYENDA.DILUIDO_ELIMINADO'), color: '#c4415d'}
        // {name: t('EXTRACCIONES.DILUCION.LEYENDA.DILUIDO'), color: '#aacfdc'},
      ],
      colorearRow: (row) => {
        if (row.diluido && !row.dilucionEliminada) {
            return 'coloreado-diluido'
        } else if (row.dilucionEliminada) {
            return 'coloreado-diluido-eliminado'
        }
      }
    }
    tableNoDiluido.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableNoDiluido.initialValues['check' + row.idElemento] = checkedFiltrosDilucion
        }
      }
    )

    const tableDiluido = {
      id: 'dilucionTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: true,
      columns: [
        {id: 'codVerraco', name: t('EXTRACCIONES.DILUCION.TABLA.COD_VERRACO')},
        {id: 'codigo', name: t('EXTRACCIONES.DILUCION.TABLA.CODIGO'), type: 'numero', numDecimales: 0},
        {id: 'fecha', name: t('EXTRACCIONES.DILUCION.TABLA.FECHA'), type: 'fecha'},
        {id: 'volumenExtraccion', name: t('EXTRACCIONES.DILUCION.TABLA.VOLUMEN_ESTRACCION'), type: 'numero', numDecimales: 0},
        {id: 'volumenDiluyente', name: t('EXTRACCIONES.DILUCION.TABLA.VOLUMEN_DILUYENTE'), type: 'numero', numDecimales: 0},
        {id: 'nombreDiluyente', name: t('EXTRACCIONES.DILUCION.TABLA.NOMBRE_DILUYENTE')},
        //{id: 'TipoDosis', name: t('EXTRACCIONES.DILUCION.TABLA.TIPO_DOSIS')},
        {id: 'numeroDosis', name: t('EXTRACCIONES.DILUCION.TABLA.NUMERO_DOSIS'), type: 'numero', numDecimales: 0}
        //{id: 'observacionesDilucion', name: t('EXTRACCIONES.DILUCION.TABLA.OBSERVACIONES')}
      ],
      rows: listDiluido,
      filtros: false,
      mostrarVerDetalle: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      onEdit: (row) => {abrirDetalles(row.idDilucion); this.state.init = false;},
      onSort: (rows) => {this.state.init = true; fetchDilucionSuccess(rows)},
      /*onDelete: (row) => onDeleteDilucion(row.idDilucion),*/
      initialValues: {},
      leyenda: [
        {name: t('EXTRACCIONES.DILUCION.LEYENDA.DILUIDO'), color: '#aacfdc'},
        {name: t('EXTRACCIONES.DILUCION.LEYENDA.DILUIDO_ELIMINADO'), color: '#c4415d'}
      ],
      colorearRow: (row) => {
        if (row.diluido && !row.dilucionEliminada) {
            return 'coloreado-diluido'
        } else if (row.dilucionEliminada) {
            return 'coloreado-diluido-eliminado'
        }
      }
    }
    tableDiluido.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableDiluido.initialValues['check' + row.idElemento] = checkedFiltrosDilucion
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalDilucionPage />
        <SimplePage t_key={"EXTRACCIONES.DILUCION."}>
        <FiltrosDilucionPage />
          <Row>
            <Col sm={6} style={{width: '50%', paddingRight: 0}}><h3>{t('COMUN.COMBOS.ESTADO_DILUCION.NO_DILUIDO')}</h3></Col>
            <Col sm={6} style={{width: '50%', paddingLeft: 15}}><h3>{t('COMUN.COMBOS.ESTADO_DILUCION.DILUIDO')}</h3></Col>
            <Col sm={6}>
              <div>
                <SimpleTablePage {...tableNoDiluido}></SimpleTablePage>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <SimpleTablePage {...tableDiluido}></SimpleTablePage>
              </div>
            </Col>
          </Row>
        </SimplePage>
      </div>
    )
  }
}

export default Dilucion