import actionTypes from '../../constants/actions/albaranManual/albaranManual'

export function fetchAlbaranesManual(filtros){
  return {
    type: actionTypes.FETCH_ALBARANES_MANUAL,
    filtros
  }
}

export function fetchAlbaranesManualSuccess(list, filtros){  
  return {
    type: actionTypes.FETCH_ALBARANES_MANUAL_SUCCESS,
    list,
    filtros
  }
}

export function onDeleteAlbaranManual(idAlbaranManual) {
  return {
    type: actionTypes.ON_DELETE_ALBARAN_MANUAL,
    idAlbaranManual
  }
}
export function imprimirAlbaranManual(idAlbaranManual) {  
  return {
    type: actionTypes.IMPRIMIR_ALBARAN_MANUAL,
    idAlbaranManual
  }
}

export function abrirModalVacioAlbaranManual () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALBARAN_MANUAL
  }
}

export function abrirModalVacioAlbaranManualSuccess () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALBARAN_MANUAL_SUCCESS
  }
}

export function abrirDetallesAlbaranManual (idAlbaranManual) {
  return {
    type: actionTypes.ABRIR_DETALLES_ALBARAN_MANUAL,
    idAlbaranManual
  }
}

export function abrirDetallesAlbaranManualSuccess (data) {
  return {
    type: actionTypes.ABRIR_DETALLES_ALBARAN_MANUAL_SUCCESS,
    data
  }
}

export function cerrarDetallesAlbaranManual () {
  return {
    type: actionTypes.CERRAR_DETALLES_ALBARAN_MANUAL
  }
}

export function cerrarDetallesAlbaranManualSuccess () {
  return {
    type: actionTypes.CERRAR_DETALLES_ALBARAN_MANUAL_SUCCESS
  }
}

export function editarAlbaranManual (values, mostrarMensaje, location, callback) {
  return {
    type: actionTypes.EDITAR_ALBARAN_MANUAL,
    values,
    mostrarMensaje,
    location,
    callback
  }
}

export function deleteModalAlbaranManualDosis(idAlbaranDosis) {
  return {
    type: actionTypes.DELETE_MODAL_ALBARAN_DOSIS_MANUAL,
    idAlbaranDosis
  }
}

export function abrirModalVacioAlbaranManualDosis(){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALBARAN_DOSIS_MANUAL
  }
}

export function abrirModalVacioAlbaranManualDosisSuccess(){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALBARAN_DOSIS_MANUAL_SUCCESS
  }
}

export function abrirDetallesAlbaranManualDosis (row) {
  return {
    type: actionTypes.ABRIR_DETALLES_ALBARAN_DOSIS_MANUAL,
    row
  }
}

export function abrirDetallesAlbaranManualDosisSuccess (data) {
  return {
    type: actionTypes.ABRIR_DETALLES_ALBARAN_DOSIS_MANUAL_SUCCESS,
    data
  }
}

export function cerrarDetallesAlbaranManualDosis () {
  return {
    type: actionTypes.CERRAR_DETALLES_ALBARAN_DOSIS_MANUAL
  }
}

export function cerrarDetallesAlbaranManualDosisSuccess () {
  return {
    type: actionTypes.CERRAR_DETALLES_ALBARAN_DOSIS_MANUAL_SUCCESS
  }
}

export function editarAlbaranManualDosis (values, guardarYnuevo, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_ALBARAN_DOSIS_MANUAL,
    values,
    guardarYnuevo,
    onSuccessCallback,
    onErrorCallback
  }
}

export function editarAlbaranManualDosisSuccess (list) {
  return {
    type: actionTypes.EDITAR_ALBARAN_DOSIS_MANUAL_SUCCESS,
    list
  }
}

export default {
  fetchAlbaranesManual,
  fetchAlbaranesManualSuccess,  
  onDeleteAlbaranManual,
  imprimirAlbaranManual,  
  abrirModalVacioAlbaranManual,
  abrirModalVacioAlbaranManualSuccess,
  abrirDetallesAlbaranManual,
  abrirDetallesAlbaranManualSuccess, 
  cerrarDetallesAlbaranManual,
  cerrarDetallesAlbaranManualSuccess,
  editarAlbaranManual,  
  deleteModalAlbaranManualDosis,  
  abrirModalVacioAlbaranManualDosis,
  abrirModalVacioAlbaranManualDosisSuccess,
  abrirDetallesAlbaranManualDosis,
  abrirDetallesAlbaranManualDosisSuccess,
  cerrarDetallesAlbaranManualDosis,
  cerrarDetallesAlbaranManualDosisSuccess,
  editarAlbaranManualDosis,
  editarAlbaranManualDosisSuccess
}
