import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import { Delete, AddCircleOutline, Warning } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosModalAlbaran extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false,
      showErrorGranja: false
    }   
  }

  componentDidUpdate (prevProps) {
   
  }  

  render () {
  const {
    t, hayCliente, hayGranja, auth: { permisos }, albaran: {filtros = {}},
    actions: {abrirModalVacioAlbaranManualDosis}
  } = this.props
  const tKey = 'ALBARANES.MODAL.'

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">              
              <div className="button-group buttons-min">
                <button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && (abrirModalVacioAlbaranManualDosis)}
                >
                  <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                </button>                
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosModalAlbaran'
})(FiltrosModalAlbaran))
